.minimal-card-container {
  display: flex;
  width: 100%;
  cursor: pointer;

  &__background-image {
    width: 100px;
    height: 74px;
    object-fit: cover;
    border-radius: @radius-default;
  }

  &__info {
    width: 100%;
    margin-left: 1rem;

    &__title {
      font-size: @font-size-small;
      font-weight: bold;
    }
  }
}
