.certificate-page,
.certificate-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 1.5rem;

  h1,
  h3,
  p {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.25px;
  }

  h2 {
    font-family: 'MS Madi', sans-serif;
    letter-spacing: -0.25px;
  }
}

.certificate-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  gap: 2rem;
}

.certificate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;

  h1 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    width: 100%;
    text-align: left;
  }
}

.certificate-content-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .certificate-content-info-title {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #707780;
  }

  .certificate-content-info-description {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }
}

.certificate-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    width: 100%;
    text-align: left;
  }

  hr {
    width: 100%;
    max-width: 250px;
    margin: 0rem 0 1rem 0;
    border: 1px solid #707780;
  }

  .certificate-footer-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 300px;

    .certificate-footer-icon-content {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
    }

    .certificate-medallion-icon {
      position: absolute;
      top: -1.25rem;
      right: .5rem;
    }
  }

  .certificate-footer-signature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    h3 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      width: 100%;
      text-align: left;
    }

    p {
      font-weight: 400;
    }
  }
}

.certificate-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 425px;
  gap: 2rem;
}

.certificate-error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.certificate-error-content-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  h1 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
}

@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;