.sidebar-card {
  width: 100%;
  position: relative;
  color: @primary-color-lms;

  &__img-container {
    width: 100%;
    height: 256px;
  }

  &__img-skeleton {
    padding: 2rem;
    width: 100%;
  }

  &__img-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 95%;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__label {
        margin: 2rem 4rem 1rem 0;
        font-size: 20px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        line-height: 120%;
        text-align: start;
      }
    }

    &__progress {
      &__classes {
        font-size: 16px;
        margin-bottom: 5px;
      }

      &__percentage {
        display: flex;
        align-items: center;

        &__label {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    &__see-more {
      display: flex;
      justify-content: flex-end;
      font-weight: 700;
      font-size: 16px;
    }
  }
}
