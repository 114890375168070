.locked-activity-container {
  width: 100%;
  height: calc(100vh - @header-heigth - @navbar-lms - @navigation-button-heigth);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    width: 261px;
    height: 212px;
  }

  &__content-date {
    color: @gray-text-dark-title;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-top: 58px;

    &__bold {
      font-weight: 700;
    }
  }
}