.final-question-container {
  margin-top: 30px;

  &__answer {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__dragAndDrop{
      margin-top: 2rem;
    }
  }

  &__submit-section {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    font-size: @font-size-large;

    &__button {
      padding: 7px 60px;
      background-color: @primary-color-lms;
      border-radius: 12px;
      color: #fff;
      width: 50%;
      height: 56px;
    }
  }

  &__feedback {
    margin: 30px 0;
    width: 100%;
  }
}

@media screen and (max-width: 476px) {
  .final-question-container {
    &__submit-section {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 706px) {
  .final-question-container {
    &__submit-section {
      display: flex;
      justify-content: center;
    }
  }
}