.course-container {
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 1rem;

  &__title {
    font-weight: bold;
    font-size: @font-size-largest;
    color: @primary-color-lms;
    margin-left: 1rem;
  }

  &__course-content {
    margin: 2rem 0 4rem 0;
  }

  &__course-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;

    &__empty-container {
      width: 100%;
      text-align: center;

      &__image {
        max-width: fit-content;
      }

      &__title {
        color: @primary-color-lms;
        font-size: @font-size-largest;
        font-weight: bold;
      }
    }
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .course-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .course-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0;
    margin-top: 2rem;

    &__title {
      padding-left: 0.5rem;
      margin-left: 0;
      margin-bottom: 1rem;
    }

    &__course-list {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      

      &__empty-container {
        &__title {
          font-size: @font-size-larger;
        }
      }
    }
  }
}