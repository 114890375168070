.popover-products {
  width: 30.5rem;
};

@media screen and (max-width: 1000px) {
  .popover-products {
    width: 20rem;
  };
}

@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;