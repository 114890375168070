.sidebar-dropdown-class {
  display: flex;
  flex-direction: column;

  &__class {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &__inner {
      max-width: @sidebar-container-widht;
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 100%;
      font-weight: 500;
      padding: 15px 40px;
      position: relative;

      &--start::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 57px;
        border-left: 1.5px dashed @text-gray;
        z-index: -1;
      }

      &--middle::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 57px;
        height: 100%;
        border-left: 1.5px dashed @text-gray;
        z-index: -1;
      }

      &--end::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 57px;
        height: 100%;
        border-left: 1.5px dashed @text-gray;
        z-index: -1;
      }

      &--selected {
        background-color: @primary-color-lms;

        &__locked {
          background-color: @sidebar-selected !important;
        }
      }

      &--disabled {
        color: @black-text-dark;
        background-color: @sidebar-disable;
      }

      &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        line-height: 150%;
        text-align: start;
        cursor: pointer;
        font-weight: 600;
        flex-grow: 1;

        &--selected {
          color: #fff;
          font-weight: 600;

          &__locked {
            color: @black-text-dark;
          }
        }
      }
    }
  }

  &__activity {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &--selected {
      background: @primary-color-lms;
      font-weight: 600;

      &__locked {
        background-color: @sidebar-selected !important;
      }
    }

    &--disabled {
      color: @black-text-dark;
      background-color: @sidebar-disable;
    }

    &__title {
      margin-left: 2rem;
      width: 90%;
      cursor: pointer;

      &--selected {
        color: white;
        font-weight: 600;

        &__locked {
          color: @black-text-dark;
        }
      }
    }

    &__inner {
      max-width: @sidebar-container-widht;
      width: 100%;
      padding: 7px 49px;
      position: relative;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 57px;
        height: 100%;
        border-left: 1.5px dashed @text-gray;
        z-index: -1;
      }
    }
  }

  &__divisor {
    height: 1px;
    background-color: @secondary-gray;
    width: 90%;
    margin: 0 auto;
    border: none;
  }

  button {
    width: 100%;
  }
}
