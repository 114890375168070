.conversation-container {
  background-color: @primary-gray;
  max-height: 100vh;

  &__message-list {
    height: calc(100vh - 150px);
    padding: 0 3.5vw;
    overflow-y: auto;
  }

  &__input {
    padding: 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .conversation-container {
    &__message-list {
      height: calc(100vh - 150px);
    }

  }
}

@media screen and (max-width: 576px) {
  .conversation-container {
    &__message-list {
      height: calc(100vh - 140px);
    }

  }
}