.statusDeadline-container {
  width: 100%;

  &__content {
    width: 100%;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.787276px solid #C5C1C1;;

    &__background {
      position: absolute;
      width: 236px;
      height: 100%;
      top: 10px;
      z-index: -1;
    }

    &__label {
      color: @primary-color-lms;
      font-weight: 700;
      margin-right: 45px;
    }

    &__date {
      margin-right: 5rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .statusDeadline-container {
    &__content {
      flex-direction: column;
      height: 120px;

      &__background {
        width: 226px;
        height: 56px;
        top: 84px;
      }

      &__date {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .statusDeadline-container {
    &__content {
      flex-direction: column;
      height: 80px;

      &__background {
        width: 256px;
        height: 66px;
        top: 36px;
      }

      &__date {
        margin-right: 0;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .statusDeadline-container {
    &__content {
      flex-direction: column;
      height: 80px;
      padding-top: 36px;

      &__background {
        width: 256px;
        height: 66px;
        top: 36px;
      }
    }
  }
}