.activity-container {
  margin-top: 30px;

  &__title {
    font-size: 30px;
    margin-bottom: 70px;
    display: block;
  }

  &__description {
  }

  &__external-reference {
    margin-top: 30px;
  }

  &__answer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
  }

  &__feedback{
    margin: 30px 0;
    width: 100%;
  }
}
