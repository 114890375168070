.question-upload-test-container {
  margin-top: 30px;

  &__title {
    margin-bottom: 1.25rem;
  }

  &__answer {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__dragAndDrop{
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 476px) {
  .question-upload-test-container {
    &__submit-section {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 706px) {
  .question-upload-test-container {
    &__submit-section {
      display: flex;
      justify-content: center;
    }
  }
}