@import '@portal/styles/_parameters';

.modal-steps-onboarding {
  &__body {
    padding: 3rem;
  }

  &__description {
    h1 {
      font-size: 20px;
      color: @primary-color-lms;
      font-weight: bold;
    }

    p {
      margin-bottom: 0 !important;
    }

    &__config-group-items {
      margin-top: 1rem;
      grid-template-columns: 5.5rem 1fr;
      gap: 1rem;
      align-items: center;

      &__left {
        justify-self: end;
      }

      &__icon {
        display: flex;
        align-items: center;
        color: @primary-color-lms;
      }

      &__see-details {
        color: @primary-color-lms;
        text-decoration: underline;
      }
    }
  }

  &__gif {
    margin-bottom: 1rem;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  }

  &__gif-container {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  &__gif-description {
    font-size: 12px;
    font-style: italic;
    color: #576B75;
  }

  &__footer {
    margin-top: 1.5rem;

    &__navigation {
      justify-content: space-between;

      &__right {
        gap: 1rem;
      }
    }

    &__progress-bar {
      margin-top: 0.75rem;

      justify-content: flex-end;
      align-items: center;
      gap: 1rem;

      span {
        font-weight: 700;
      }
    }
  }
}

.modal-steps-onboarding--step {
  // STUDENT
  &__navigation-menu {
    right: 16vw;
  }

  &__electives {
    right: 16vw;
    top: 5vh !important
  }

  &__see-tutorial {
    top: 32vh !important;
  }

  // STUDENT WITH GROUPS
  &__navigation-menu--has-groups {
    right: 16vw;
  }

  &__electives--has-groups {
    right: 16vw;
    top: 30vh !important
  }

  // TUTOR
  &__navigation-menu-tutor {
    right: 16vw;
  }

  &__videos {
    right: 16vw;
    top: 58vh !important;
  }

  &__my-groups {
    right: 16vw;
    top: 6vh !important;
  }

  &__card-group {
    top: 36vh !important;
    left: 5vw;
  }

  &__card-group--admin {
    top: 12vh !important;
    left: 10vw;
  }

  //TUTOR WITH VIDEOS
  &__navigation-menu-tutor--has-videos {
    right: 16vw;
  }

  // LMS EDUCATOR
  &__certificates {
    left: 11vw;
  }

  &__trails {
    top: 57vh !important;
    right: 15.5vw;
  }

  // LMS EDUCATOR WITH GROUPS
  &__certificates--has-groups {
    left: 11vw;
  }

  &__trails--unique-trail {
    top: 1vh !important;
    right: 15.5vw;
  }

  // ELECTIVE
  &__see-tutorial-elective {
    top: 24vh !important;
    left: 6vw !important;
  }
}

@media screen and (min-width:1920px) {
  .modal-steps-onboarding--step {
    // STUDENT
    &__navigation-menu {
      top: 30vh !important;
      right: 23vw;
    }

    &__electives {
      top: 5vh !important;
      right: 23vw;
    }

    &__see-tutorial {
      top: 25vh !important;
      left: 10vw;
    }

    // STUDENT WITH GROUPS
    &__navigation-menu--has-groups {
      top: 10vh !important;
      right: 23vw;
    }

    &__electives--has-groups {
      top: 24vh !important;
      right: 23vw;
    }

    // TUTOR
    &__navigation-menu-tutor {
      top: 25vh !important;
      right: 23vw;
    }

    &__videos {
      top: 47vh !important;
      right: 23vw;
    }

    &__my-groups {
      right: 23vw;
      top: 5vh !important;
    }

    &__card-group {
      top: 49vh !important;
      right: 5vw;
      left: auto;
    }

    &__card-group--admin {
      top: 12vh !important;
      right: 5vw;
      left: auto;
    }

    //TUTOR WITH VIDEOS
    &__navigation-menu-tutor--has-videos {
      right: 23vw;
    }

    // LMS EDUCATOR
    &__certificates {
      top: 30vh !important;
      right: 2vw;
      left: auto;
    }

    &__trails {
      top: 46vh !important;
      right: 22.5vw;
    }

    // LMS EDUCATOR WITH GROUPS
    &__certificates--has-groups {
      left: auto;
      right: 2vw;
    }

    &__trails--unique-trail {
      right: 22.5vw;
      top: 20vh  !important;
    }

    // ELECTIVE
    &__see-tutorial-elective {
      top: 18vh !important;
      left: 15vw !important;
    }
  }
}

@media screen and (max-width:768px) {
  .modal-steps-onboarding {
    &__body {
      padding: 2rem;

      &__button-previous {
        width: 2rem;
        height: 2rem;
        background-color: @primary-color-lms;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 1rem;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.34);
      }
    }

    &__footer {
      &__navigation {
        flex-direction: column-reverse;
        gap: 1rem;

        &__right {
          flex-direction: column;
          width: 100%;
        }

        &__button {
          width: 100%;
        }
      }
    }
  }

  .modal-steps-onboarding--step {
    // STUDENT
    &__navigation-menu {
      right: auto
    }

    &__electives {
      top: 15vh !important;
      right: auto;
    }

    &__see-tutorial {
      top: 11vh !important;
      left: auto;
    }

    // STUDENT WITH GROUPS
    &__navigation-menu--has-groups {
      right: auto
    }

    &__electives--has-groups {
      top: 35vh !important;
      right: auto;
    }

    // TUTOR
    &__navigation-menu-tutor {
      top: 25vh !important;
      right: auto;
    }

    &__videos {
      top: 47vh !important;
      right: auto;
    }

    &__my-groups {
      right: auto;
      top: 10vh !important;
    }

    &__card-group {
      top: 1vh !important;
      right: auto;
      left: auto;
    }

    //TUTOR WITH VIDEOS
    &__navigation-menu-tutor--has-videos {
      right: auto
    }

    // LMS EDUCATOR
    &__certificates {
      top: 11vh !important;
      right: auto;
      left: auto
    }

    &__trails {
      top: 15vh !important;
      right: auto;
    }

    // LMS EDUCATOR WITH GROUPS
    &__certificates--has-groups {
      left: auto;
      right: auto;
    }

    &__trails--unique-trail {
      top: 30vh  !important;
      right: auto;
    }

    // ELECTIVE
    &__see-tutorial-elective {
      top: 11vh !important;
      left: auto !important;
    }
  }
}


@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;