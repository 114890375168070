.contact-profile-container {
  &__thumb {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__online {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 2px;
    right: -5px;
  }

  &__user-name {
    font-size: 16px;
    font-weight: 500;
    &--new {
      color: @primary-color-lms;
    }
  }

  &__new-message-indicator {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: @primary-color-lms;
  }

  &__availability {
    font-size: 10px;
  }

  &--isSelected {
    background-color: @secondary-gray;
    padding: 0 15px;
  }
}

.contact-profile-container:hover {
  cursor: pointer;
}
