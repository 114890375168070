.user-dropdown {
  padding: 0 10px;

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid @primary-color-lms;

    &__image {
      width: 16px;
    }
  }

  &__name {
    padding-left: 10px;
    position: relative;
    top: 3px;
  }

  &__item {
    display: block;
    color: @primary-color-lms;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 0;

    &__chat {
      display: none;
    }

    &__help {
      display: none;
    }

    &__block {
      display: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .user-dropdown {
    &__item {
      &__chat {
        display: block;
        color: @primary-color-lms;
        font-size: 14px;
        cursor: pointer;
        padding: 5px 0;
      }

      &__help {
        display: block;
        color: @primary-color-lms;
        font-size: 14px;
        cursor: pointer;
        padding: 5px 0;      
      }

      &__block {
        display: block;
        color: @primary-color-lms;
        font-size: 14px;
        cursor: pointer;
        padding: 5px 0;
      }
    }
  }
}
