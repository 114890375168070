.top-bar-chat {
  background-color: @primary-color-lms;
  margin-top: @header-heigth;

  &__button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    margin-right: 1vw;
  }

  &__input {
    height: 100%;
    width: 250px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
  }

  &__input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 0.8;
  }

  &__input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
    opacity: 0.8;
  }

  &__input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
    opacity: 0.8;
  }

  &__input:focus {
    outline: none;
  }

  &__label {
    display: none;
    width: 100%;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }

  &__profile-info {
    width: 100%;
    display: none;
    &__thumb {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }

    &__name {
      color: #fff;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 576px) {
  .top-bar-chat {
    &__icon {
      font-size: 20px;
    }

    &__magnifier {
      display: none;
    }

    &__input {
      display: none;
    }

    &__label {
      display: inline-block;
    }

    &__profile-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
