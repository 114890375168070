.banner {
  max-width: 100vw;

  &__banner-container {
    height: calc(100vh - @header-heigth - @bottom-scape);
    max-height: 270px;
    cursor: pointer;

    &__content {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: left;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner {
    &__banner-container {
      max-height: 210px;
    }
  }
}