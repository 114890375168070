.final-evaluation-section {
  width: 100%;
  min-height: calc(100vh - @header-heigth - @navigation-button-heigth);  
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 50px;
  overflow-y: auto;
  position: relative;
  padding-bottom: 2rem;

  &__header {
    width: 100%;
  }

  &__header-questions {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;

    &__back {
      color: @primary-color-lms;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-weight: 700;
    }
  }

  &__test-grade {
    margin-top: 5rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    text-align: center;

    &__title {
      font-weight: bold;
      font-size: 40px;
      color: @primary-color-lms;
    }

    &__content {
      margin-bottom: 2rem;
    }

    &__description {
      font-size: 36px;
    }

    &__grade-positive {
      margin-left: 0.5rem;
      font-size: 36px;
      color: #74D98A;
      font-weight: bold;
    }

    &__grade-negative {
      margin-left: 0.5rem;
      font-size: 36px;
      color: #FF7410;
      font-weight: bold;
    }

    &__label {
      margin-top: 1rem;
    }

    &__link {
      color: @primary-color-lms;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 1rem;
    }
  }

  &__status-date {
    margin-top: 10px;

    &__background {
      overflow: hidden;
      position: absolute;
      width: 236px;
      height: 43px;
      top: 5px;
      z-index: -1;
    }
  }

  &__title {
    margin-top: 2rem;
  }

  &__grade {
    font-size: 20px;
    font-weight: 500;
  }

  &__submit {
    margin-top: 3rem;
    margin-bottom: 2rem;
    width: 100%;
    height: 100%;
  }

  &__question-list {
    width: 100%;
    margin-bottom: 2rem;
  }

  &__upload-file {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.modal-content {
  &__label--grade {
    color: @primary-color-lms;
    font-weight: 700;
  }
}