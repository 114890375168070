.course-details {
  width: 100vw;

  &__player-wrapper {
    width: 100%;
    height: calc(100vh - @header-heigth - @bottom-scape);
    max-height: 273px;
    margin-bottom: 2rem;
  }

  &__banner {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }

  &__page-container__banner-container {
    height: calc(100vh - @header-heigth - @bottom-scape);
    max-height: 273px;
    width: 100vw;
    cursor: pointer;

    &__banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    width: 100vw;
    padding: 3.375rem 7.75rem 0 7.75rem;

    &__title-container {
      width: 100%;

      &__title {
        color: @primary-color-lms;
        font-weight: bold;
        font-size: @font-size-extreme;
      }
    }

    &__html {
      padding-bottom: 5%;
      width: 100%;

      &__wrapper {
        min-height: 12.5rem;
      }
    }
  }

  &__modal-container {
    margin: 0 -15px 0 -15px;

    &__banner {
      width: 100%;
      height: 279px;
      object-fit: cover;
      background-color: #741982;
    }

    &__title {
      color: @primary-color-lms;
      font-weight: bold;
      font-size: @font-size-larger;
    }
  }
}

.html-render-container,
.ql-editor {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
}

.ql-video {
  display: flex;
  align-self: center;
  width: 600px;
  height: 300px;
}

.course-details__navigation .MuiTabs-flexContainer {
  justify-content: space-between;
  max-width: 873px;
  min-width: auto;
}

.course-details__divider-wrapper {
  width: 100%;
  height: 1px;
  background-color: @primary-color-lms;
}

@media screen and (max-width: 768px) {
  .course-details {
    &__content {
      width: 100vw;
      padding: 3.375rem 0 0 2rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .course-details {
    &__content {
      &__title-container {
        display: flex;
        justify-content: center;

        &__title {
          font-size: @font-size-larger;
        }
      }
    }

    &__player-wrapper {
      height: 15rem;
      max-height: 15rem;
      margin-bottom: 0;
    }

    &__banner {
      height: 15rem;
    }
  }
}
