.chat-popover {
  >.ant-popover-content {
    >.ant-popover-inner {
      border-radius: 20px;
    }

    >.ant-popover-arrow {
      display: none !important;
    }
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  &__container {
    width: 432px;
    height: 556px;

    &__header {
      width: 100%;
      height: 108px;
      background: @primary-color-lms;
      padding: 32px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      display: flex;
      align-items: center;

      &__icon {
        font-size: 30px;
        font-weight: 500;
        color: #fff;
        margin-right: 1vw;
      }

      &__input {
        height: 100%;
        width: 250px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        color: #fff;
      }

      &__input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 0.8;
      }

      &__input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
        opacity: 0.8;
      }

      &__input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
        opacity: 0.8;
      }

      &__input:focus {
        outline: none;
      }
    }

    &__content {
      overflow: auto;
      height: 448px;
    }
  }
}

.activity-section {
  width: 100%;
  min-height: calc(100vh - @header-heigth - @navbar-lms - @navigation-button-heigth);
  display: flex;
  flex-direction: column;
  padding: 90px 50px;
  line-break: normal;
  position: relative;
  overflow-y: auto;

  &__status-deadline {
    position: absolute;
    top: 20px;
  }

  &__main-title {
    font-size: 30px;
    margin: 30px 0 30px 0;
    display: block;
  }

  &__external-reference {
    margin-top: 30px;
  }

  &__activity-list {
    width: 100%;
  }

  &__submit-section {
    display: flex;
    justify-content: flex-end;

    &__button {
      padding: 7px 60px;
      background-color: @primary-color-lms;
      border-radius: 12px;
      color: #fff;
      width: 50%;
      height: 56px;
    }
  }

  &__chat-pop-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: @primary-color-lms;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &__icon {
      width: 30px;
      height: 30px;
    }
  }
}

@media screen and (max-width: 576px) {
  .activity-section {
    padding: 0 20px;
    margin-top: 0;
    margin-bottom: 30px;

    &__main-title {
      font-size: 20px;
      margin: 4rem 0 1rem 0;
    }
    
    &__status-deadline {
      position: relative;
      top: 0px;
    }

    &__submit-section {
      display: flex;
      justify-content: center;
    }
  }

  .chat-popover {
    &__container {
      width: 332px;
      height: 456px;

      &__header {
        width: 100%;
        height: 88px;
        background: @primary-color-lms;
        padding: 32px;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }

      &__content {
        overflow: auto;
        height: 368px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .activity-section {
    padding: 90px 50px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 720px) and (max-width: 960px) {
  .activity-section {
    padding: 140px 50px;
  }
}