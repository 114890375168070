@import '@portal/styles/_parameters';

.atoms-button {
  width: fit-content;

  display: flex;
  
  justify-content: center;
  align-items: center;

  border-radius: 6px;

  cursor: pointer;

  font-weight: 700;
  text-transform: uppercase;

  padding: 0 0.75rem 0 0.75rem;

  transition: all 0.3s;

  white-space: nowrap;

  &__root--disabled {
    cursor: not-allowed;
  } 

  &__content {
    gap: 0.5rem;
    align-items: center
  }
}

.atoms-button--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.atoms-button--lg {
  height: 3.75rem;
  font-size: 16px;
  padding: 0 1.875rem 0 1.875rem;
}

.atoms-button--md {
  height: 3rem;
  font-size: 16px;
}

.atoms-button--sm {
  height: 2.5rem;
  font-size: 14px;
}

.atoms-button--primary {
  color: white;

  border: none;

  &:hover {
    filter: brightness(90%)
  }
}

.atoms-button--secondary {
  background-color: white;

  border: 1px solid;

  &:hover {
    filter: brightness(90%)
  }
}
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;