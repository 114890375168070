.status-container {

  &__sent-answer {
    height: 36px;
    color: @primary-green;
    font-size: 14px;
    line-height: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    &__check {
      min-width: 22px;
      min-height: 22px;
      border-radius: 100%;
      background-color: @primary-green;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }

  &__content-deadline {
    height: 36px;
    color: @gray-text-deadline;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      min-width: 22px;
      min-height: 22px;
      border-radius: 100%;
      border:solid;
      border-width: 1px;
      border-color: @gray-text-deadline;
      background-color: @gray-bg-deadline;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    &__container-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 36px;
    }

    &__container-text {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 480px) {
  .status-container {
    margin-top: 36px;
  }
}

@media screen and (max-width: 576px) {
  .status-container {
    margin-top: 24px;
    
    &__content-deadline {
      font-size: 12px;

      &__container-text {
        font-size: 10px;
      }
    }
  }
}
