.footer-container {
  padding: 0 20px;
  background-color: @header-background;

  &__logos {
    display: flex;
    justify-content: center;

    &__img {
      padding: 0 10px;
      width: 80px;
      height: 80px;

      &-tecer {
        padding: 0 10px;
        width: 120px;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &__social-media {
      display: flex;
      align-items: center;
      justify-content: center;

      &__sm {
        font-style: normal;
        font-weight: 400;
        line-height: 67px;
        letter-spacing: -0.015em;
        text-align: left;
        font-size: 14px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &--linkedin {
          width: 55px;
        }

        span {
          line-height: 1.5em;
        }
      }
    }

    &__slogan {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.015em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 992px) {
  .footer-container {
    &__right {
      &__slogan {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-container {
    &__right {
      display: flex;
      flex-direction: column-reverse;
      &__social-media {
        margin: 0 10%;
        width: 60%;
        justify-content: space-between;
        padding: 15px;
      }
      &__slogan {
        width: 100%;
        text-align: center;
        margin: 10px 0;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .footer-container {
    &__logos {
      align-items: center;
      &__img {
        padding: 0 4px;
        width: 60px;
        height: 60px;
      }
    }

    &__right {
      &__social-media {
        width: 100%;

        &--sm {
          padding: 0 3px;
        }
      }
    }
  }
}
