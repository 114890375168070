.popover-notification {
  width: 16rem;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  &__date {
    color: @gray600;
    font-size: @font-size-extra-small;
  }

  &__empty-label {
    font-size: @font-size-smallest;
  }
}

.html-render-container {
  font-size: @font-size-smallest;
  padding: 0;
}

.ant-popover-inner {
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .popover-notification {
    width: 14rem;
  }
}