@import '@portal/styles/_parameters';

.atoms-avatar {
  background-size: cover;
  background-position: center;

  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;

  cursor: pointer;
  
  justify-content: center;
  align-items: center;

  &__initials {
    font-weight: bold;
    line-height: 0;
    font-size: 24px;
  }
}

.atoms-avatar--primary {
  background-color: @white;

  &__initials {
    color: white;
  }
}

.atoms-avatar--secondary {
  background-color: white;

  &__initials {
    color: @primary-color-brand;
  }
}

.atoms-avatar--xs {
  width: 2rem;
  height: 2rem;
}

.atoms-avatar--sm {
  width: 3.5rem;
  height: 3.5rem;
}

.atoms-avatar--md {
  width: 4.9rem;
  height: 4.9rem;
}

.atoms-avatar--lg {
  width: 6.3rem;
  height: 6.3rem;
}

@media screen and (max-width: 1000px) {
  .atoms-avatar--sm {
    width: 2.1875rem;
    height: 2.1875rem;
  }
}

@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;