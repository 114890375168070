@import '@portal/styles/_parameters';

.menu-hamburguer-mobile {
  &__close-icon {
    width: 100%;

    justify-content: end;

    cursor: pointer;

    font-size: 20px;
  }

  &__body {
    background-color: @primary-color-brand;
    color: white;
    padding-left: 1rem;

    * span {
      color: white;
    }

    padding-bottom: 5rem;
  }

  &__options {
    flex-direction: column;
    gap: 1rem;

    &__item {
      gap: 0.5rem;
      align-items: center;
      font-weight: 700;
    }
  }

  &__products {
    * hr {
      background-color: #FFFFFF;
      height: 1px;
      width: 100%;
      margin-top: 0;
    }
  }
}
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;