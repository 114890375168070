.content-mobile-container {
  width: 100%;
  position: relative;

  &__header {
    position: relative;
    display: flex;
    width: 100%;
    margin: 20px 0;
    padding: 0 20px;

    &__back-icon {
      position: absolute;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__label {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
  }
  &__navigation{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
