.button {
  display: flex;
  align-items: center;
  border-radius: @radius-small;
  justify-content: center;
  transition-duration: 0.4s;

  &__primary {
    width: 11.25rem;
    height: 2.75rem;
    color: @white;
    background-color: @primary-color-lms;
    font-size: @font-size-large;

    &:hover {
      background-color: transparent;
      border: 1px solid @primary-color-lms;
      color: @primary-color-lms;
    }

    &__disabled {
      color: @white;
      font-size: @font-size-large;
      cursor: not-allowed;
      width: 11.25rem;
      height: 2.75rem;
      background-color: @gray-text-secondary;
      opacity: 1;
    }

    &__pseudo-disabled {
      color: @white;
      background-color: @gray-text-secondary;
      font-size: @font-size-large;
      cursor: pointer;
      width: 11.25rem;
      height: 2.75rem;
      opacity: 0.8;
    }
  }

  &__secondary {
    width: 11.25rem;
    height: 2.75rem;
    background-color: none !important;
    border: 1px solid @primary-color-lms;
    border-radius: @radius-small;
    font-size: @font-size-large;

    &__disabled {
      color: @white;
      font-size: @font-size-large;
      cursor: not-allowed;
      width: 11.25rem;
      height: 2.75rem;
      background-color: @gray-text-secondary;
      opacity: 1;
    }

    &__isEnrollmentDisabled {
      font-size: @font-size-large;
      cursor: not-allowed;
      width: 11.25rem;
      height: 4rem;
      background-color: white;
      padding: 10px;
      opacity: 1;
      border: 1px solid @primary-color-lms;
    }
  }

  &__smallest {
    width: 28px;
    height: 32px;
  }

  &__smaller {
    width: 52px;
    height: 32px;
    font-weight: 600;
  }

  &__small {
    width: 6rem;
    height: 2rem;
    font-size: @font-size-smaller;
  }

  &__medium {
    width: 7.5rem;
    height: 2rem;
    font-size: @font-size-smaller;
  }

  &__large {
    width: 10.6rem;
    height: 3.15rem;
    font-size: @font-size-large;
  }

  &__full {
    width: 100%;
    min-height: 2.75rem;
    height: 100%;
  }

  &__fit-content {
    min-width: 11.25rem;
    width: fit-content;
  }

  &__content {
    &__row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5% 0 5%;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 1% 0 1%;
        color: @primary-color-lms;
      }
    }

    &__title {
      font-weight: bold;

      &__secondary {
        color: @primary-color-lms;
      }
    }
  }
}
