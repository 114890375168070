.atoms-input {
  &__root {
    height: 2.8rem;
    padding: 0 0.75rem 0 0.75rem;

    border-radius: 6px;
    border: 1px solid #D5D5D5;

    align-items: center;

    background-color: white;
  }

  &__root--disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  &__input {
    width: 100%;
    height: 100%;

    padding-right: 0.75rem;

    border: none;
    border-radius: 6px;

    &:focus {
      outline: none
    }
  
    &::placeholder {
      color: #7F8182
    }
  }

  &__input--disabled {
    background-color: #f5f5f5;
    color: #00000040;
    cursor: not-allowed
  }
}
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;