.input-chat {
  background-color: @primary-gray;
  position: relative;

  &__button-refresh {
    width: 66.41px;
    height: 66.41px;
    margin-left: 24px;
    background: @primary-color-lms;
    margin-top: -10px;
    border-radius: 5px;

    > img {
      width: 24px;
      height: 24px;
    }
  }

  &__file-container {
    position: absolute;
    top: -50px;
    left: 0;
    padding: 8px 5px 20px;
    border: 1px solid @primary-color-lms;
    border-radius: 5px;
    background-color: @primary-gray;
    display: flex !important;
    flex-direction: row;

    &__file-content__icon {
      width: 16px;
      height: 16px;
    }

    > p {
      margin: 0 0 0 5px;
    }
  }

  &__input-container {
    padding: 8px 5px;
    border: 1px solid @primary-color-lms;
    background-color: @primary-gray;
    border-radius: 5px;
    display: flex !important;
    flex-direction: row;
    margin-top: -10px;

    &__input {
      border: 0;
      background-color: transparent;
      display: flex;
      flex: 1;
      padding-right: 5px;
      resize: none;

      &__send-icon {
        svg {
          width: 20px;
          height: 20px;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    &__input:focus {
      outline: none;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 5px;

      > label {
        margin: 0;

        & + button {
          margin-left: 20px;
        }
      }

      &__button {
        border: none;
        cursor: pointer;

        &__file-picker {
          display: none;
        }
      }
    }
  }

  &__mic {
    border: 1px solid @primary-color-lms;
    border-radius: 5px;
  }
}

@media screen and (max-width: 576px) {
  .input-chat {
    &__input-container {
      padding: 10px 5px;
    }
    &__icon {
      width: 20px;
    }
  }
}
