.activity-section {
  width: 100%;
  min-height: calc(100vh - @header-heigth - @navbar-lms - @navigation-button-heigth);
  display: flex;
  flex-direction: column;
  padding: 90px 50px;
  line-break: normal;
  position: relative;
  overflow-y: auto;

  &__status-deadline {
    position: absolute;
    top: 20px;
  }

  &__main-content {
    padding-bottom: 3rem;
  }

  &__main-title {
    font-size: 30px;
    margin-bottom: 50px;
    display: block;
    color: @primary-color-lms;
    font-weight: 600;
  }

  &__collapse-container {
    &__panel {
      width: 100%;
      margin-bottom: 1rem;
      border-radius: @radius-medium  !important;
      box-shadow: @shadow-default;
      font-size: @font-size-larger;
      font-weight: bold;

      &__extra {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__notification {
          margin-left: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          border-radius: @radius-circle;
          background-color: @gray600;
          color: white;
        }
      }

      &__loading__inner {
        width: 100%;
        padding: 3rem 0 3rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__button {
    font-weight: bold;
    color: @primary-color-lms;
  }
}

.ant-collapse-borderless>.ant-collapse-item {
  padding: 0 1rem 0 1rem;
  align-items: center;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 5px;
}

.collapseUser-container {
  margin-bottom: 0;
}

.profileImg-container {
  width: 40px;
  height: 40px;
}

.collapseUser-container__panel {
  width: 100%;
}

.ant-collapse-content-box {
  margin-left: 1.5rem;
}

@media screen and (max-width: 576px) {
  .activity-section {
    padding: 0 1rem !important;
    margin-top: 0;
    margin-bottom: 30px;

    &__main-title {
      font-size: 20px;
      margin: 4rem 0 1rem 0;
    }

    &__status-deadline {
      position: relative;
      top: 0px;
    }

    &__submit-section {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 720px) {
  .activity-section {
    padding: 5.625rem 3.125rem;
    margin-bottom: 1.875rem;
  }
}

@media screen and (min-width: 720px) and (max-width: 960px) {
  .activity-section {
    padding: 8.75rem 3.125rem;
  }
}