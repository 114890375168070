.pagination-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 0;

  &__ellipsis {
    height: 100%;
    color: #fff;
    font-weight: 600;

    &--tecer {
      color: @primary-color-lms;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30px;

    &__dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s;
      border: 0;

      &--selected {
        width: 30px;
        border-radius: 50px;
        transition: 0.5s;
      }

      &--tecer {
        background-color: @primary-color-lms;
      }
    }

    &__dot:hover {
      cursor: pointer;
    }

    &__number {
      color: #fff;
      font-weight: 600;
      margin-top: 10px;

      &--tecer {
        color: @primary-color-lms;
      }
    }
  }
}
