.message-container {
  margin: 1vw 0;
  word-wrap: break-word;

  &__message {
    background-color: @primary-purple;
    padding: 16px;
    border-radius: 15px;
    color: #fff;

    &--me {
      background: @secondary-purple;
    }

    &__info{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      
      svg{
        width: 18px;
        height: 18px;
      }

    }

    &__photo {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }

  &__status {
    color: @text-gray;
  }
}
