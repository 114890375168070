.searchDropdown {
  &-container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: @radius-small;
    padding: 15px;

    &__search {
      display: flex;
      align-items: center;
      padding: 4px;
      background-color: @gray-bg;
      border: 1px solid @gray300;
      border-radius: @radius-small;
      flex: 1;
    
      &__icon {
        font-size: 15px;
        margin: 0 15px;
      }
    
      &__input {
        width: 100%;
    
        &__content {
          background-color: @gray-bg;
          border: 0; 
          text-align: 'left';
        }
      }
    }
  }

}

