.collapseUser-container {
  margin-bottom: 8%;
  background-color: white;
  padding: 0 3.75rem 0 1rem;

  &__panel {
    width: 90%;
    margin-bottom: 1rem;
    background-color: @gray50;
    border-radius: @radius-medium !important;
    box-shadow: @shadow-default;
    font-size: @font-size-larger;
    font-weight: bold;

    &__extra {
      display: flex;
      align-items: center;
      padding: 0 2rem 0 2rem;

      &__notification {
        margin-left: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: @radius-circle;
        background-color: @gray600;
        color: white;
      }
    }
  }
}

.collapseUser-content {
  padding: 0 3.75rem 2.75rem 3.75rem;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-header {
  height: 4.5rem;
  align-items: center;
}

.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  height: 4rem;
}

@media screen and (max-width: 576px) {
  .collapseUser-container {
    padding: 0;
  }

  .collapseUser-content {
    padding: 0 1rem 1rem 1rem;
  }
}