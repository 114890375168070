.answer-question-container {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &__navigation-bar {
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      display: flex;
      list-style-type: none;
      margin: 0;
      width: 100%;
      padding: 0;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 10px 0;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transition: 0.5s;

        &--selected {
          background-color: @primary-color-lms;
          color: #fff;
        }

        &--disabled {
          color: @text-gray;
          cursor: not-allowed;
        }

        &--required {
          color: @primary-red;
        }
      }
    }
  }

  &__input-textarea-container {
    width: 100%;
    margin: 1rem 0 1rem 0;

    &__textarea {
      resize: none;
      width: 100%;
      height: 100%;
      border: 1px solid @secondary-gray;
      padding: 10px;

      &--required {
        border: 2px solid @primary-red;
      }
    }

    &__characters {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__input-attachment-container {
    width: auto;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: @gray300;

    &__draggable_container {
      width: 100%;
      height: 9.125rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      border-radius: 15px;
      margin: 16px 0;
      flex-grow: 1;

      &--required {
        border: 2px solid @primary-red;
      }

      &__label {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: @gray600;

        &__info-size {
          font-size: 12px;
        }

        span {
          margin-left: 10px;
          font-weight: 800;
        }
      }
    }

    &__link {
      font-size: 18px;
    }
  }

  &__review-tutor {
    font-weight: normal;
    font-size: @font-size-small;

    &__answerStudent {
      font-size: 16px;
      text-align: left;
      font-weight: bold;
    }

    &__answerStudent-text {
      font-size: 16px;
      text-align: left;
    }

    &__radio-group {
      margin: 1rem 0 1rem 0;

      &__radio {
        min-width: 114px;
        height: 48px;
        padding: 1rem;
        margin: 0 1rem 1rem 0;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    &__expected-answer {
      font-size: 16px;
      font-weight: bold;
      color: @answer-color-lms;
    }

    &__expected-answer-student {
      font-size: 16px;
      color: @answer-color-lms;
    }

    &__button {
      margin-top: 2rem;
      color: @gray600;
      text-decoration: underline;
    }
  }

  &__render-status-date {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.required-field-text {
  color: @primary-red;
  max-width: 600px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

@media screen and (max-width: 720px) {
  .answer-question-container {
    &__review-tutor {
      margin-bottom: 5rem;
    }
  }
}