.chat-container {
  background-color: @primary-gray;
  max-height: calc(100vh - 80px - @header-heigth);

  &__contact-list {
    border-right: 1px solid #fff;
    height: calc(100vh - 80px - @header-heigth);
    overflow-y: auto;
  }

  &__message-list {
    height: calc(100vh - 160px - @header-heigth);
    padding: 0 3.5vw;
    overflow-y: auto;
  }

  &__input {
    padding: 10px 0;
  }
}
