.carousel {
  &__root {
    display: flex;
    position: relative;
  }

  &__body {
    overflow-x: auto;
    scroll-behavior: smooth;
    display: flex;
    padding: 0.5rem 0;

    &::-webkit-scrollbar {
      height: 0;
    }
  }
}
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;