.collapse-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__play-button {
        font-size: 3rem;
    }

    &__background-image {
        width: 15.6rem;
        height: 10rem;
        object-fit: cover;
    }
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
    margin-left: 0.5rem;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;
}

.ant-collapse-expand-icon {
    display: flex;
    align-self: center;
}