body {
  background: '#FFF';
}

.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &__container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 2px 2px 8px 8px;

    &__image {
      max-width: 550px;
      max-height: 450px;
    }

    &__content {
      width: 32.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 2px 2px 8px 8px;

      &__input {
        width: 80%;
  
        &__email {
          margin-top: 2.5rem;
        }
  
        &__password {
          margin-top: 1.25rem;
        }
      }
  
      &__buttons {
        display: flex;
        margin-top: 6%;
  
        &__password {
          display: flex;
          flex-direction: column;
          margin-right: 6.5rem;
  
          &__link {
            text-decoration: underline;
          }
        }
  
        &__button {
          height: 3rem;
          width: 8.75rem;
          border-radius: 26px;
          border: 1px solid #dce2e4;
          background: '#F00';
          color: #004daa;
          font-weight: bold;
          cursor: pointer;
        }
  
        &__button:hover {
          border: 1px solid #dce2e4;
          background: #004daa;
          color: '#F00';
        }
      }
  
      &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 14%;
        background-color: '#F00';
        width: 100%;
        height: 6rem;
        border-radius: 0 0 8px 8px;
  
        &__link {
          text-decoration: underline;
        }
      }
    }
  }

  &__description-modal {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .login {
    min-height: none;

    &__container {
      width: none;

      &__image {
        display: none;
      }
    }
  }
}

@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;