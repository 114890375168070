.feedback {
  width: 100%;
  display: flex;

  &__thumb {
    margin-right: 20px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }

  &__info {
    &__name {
      font-weight: 600;
      font-size: 16px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
    }

    &__question {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
