.sidebar-final-task-item {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__inner {
    width: 100%;
    max-width: @sidebar-container-widht;
    padding: 15px 40px;
    position: relative;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    align-items: center;

    &--disabled {
      background-color: @secondary-gray;
    }

    &--selected {
      background-color: @primary-color-lms;
      color: #fff;

      &__locked {
        background-color: @sidebar-selected !important;
        color: @black-text-dark;
      }
    }

    &__button-container {
      width: 100%;
      &__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__label {
          font-size: 16px;
          font-weight: 500;
          margin-left: 0.5rem;
          width: 90%;
          cursor: pointer;

          &__locked {
            font-weight: 600;
          }
        }
      }
    }

    &__start-line::after {
      content: "";
      position: absolute;
      height: 100%;
      left: 57px;
      top: 0;
      bottom: 0;
      border-left: 1.5px dashed @text-gray;
    }

    &__middle-line::after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 57px;
      border-left: 1.5px dashed @text-gray;
    }

    &__end-line::after {
      content: "";
      position: absolute;
      height: 50%;
      top: 0;
      left: 57px;
      border-left: 1.5px dashed @text-gray;
    }
  }
}
