.modal-start-onboarding {
  &__body {
    padding: 6.3125rem 8.75rem;
  }

  &__header {
    flex-direction: column;

    gap: 1.5rem;

    margin-bottom: 2rem;
  }

  &__title-product {
    font-size: 20px;
    font-weight: 700;
  }

  &__card-logo {
    width: 9.4275rem;
    height: 6.6875rem;

    justify-content: center;
    align-items: center;

    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  &__logo {
    height: 3.125rem !important;
    object-fit: contain;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
  }

  &__footer {
    margin-top: 1.5rem;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .modal-start-onboarding {
    &__body {
      padding: 2rem 2.5rem;
    }

    &__footer {
      flex-direction: column-reverse;
      gap: 1rem;

      &__button {
        width: 100%;
      }
    }
  }
}


@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;