@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

html,
body {
  font-family: 'Raleway', sans-serif !important;
}

@import '~antd/dist/antd';

@import './_parameters';
@import './_globals';
@import './_overrides';


// stacks
@import 'src/pages/LMS/LMSNavigationStack';

// pages
@import 'src/pages/Auth/Login/Login';
@import 'src/pages/LMS/Home/HomePage/HomePage';
@import 'src/pages/LMS/Chat/ChatPage/ChatPage';
@import 'src/pages/LMS/Groups/GroupsPage/Groups';
@import 'src/pages/LMS/Groups/ModalSendReportStudents/ModalSendReportStudents';
@import 'src/pages/LMS/Profile/ProfilePage/ProfilePage';
@import 'src/pages/LMS/Content/ContentPage/ContentPage';
@import 'src/pages/LMS/Courses/CoursesPage/CoursesPage';
@import 'src/pages/LMS/Redirect/RedirectPage/RedirectPage';
@import 'src/pages/LMS/Chat/ConversationPage/ConversationPage';
@import 'src/pages/LMS/Content/ContentMobilePage/ContentMobilePage';
@import 'src/pages/LMS/CourseDetails/CourseDetailsPage/CourseDetailsPage';

// ui
@import 'src/components/v1/UI/Atoms/ProfileImg/ProfileImg';
@import 'src/components/v1/UI/Molecules/CollapseUser/CollapseUser';
@import 'src/components/v1/UI/Atoms/AdvancedCollapse/AdvancedCollapse';

// components
@import 'src/components/v1/Btn/Btn';
@import 'src/components/v1/Card/Card';
@import 'src/components/v1/Banner/Banner';
@import 'src/components/v1/Footer/Footer';
@import 'src/components/v1/Loading/Loading';
@import 'src/components/v1/MyGroups/MyGroups';
@import 'src/components/v1/SearchBar/SearchBar';
@import 'src/components/v1/RenderPDF/RenderPDF';
@import 'src/components/v1/RenderHTML/RenderHTML';
@import 'src/components/v1/VideoModal/VideoModal';
@import 'src/components/v1/Pagination/Pagination';
@import 'src/components/v1/TopBarChat/TopBarChat';
@import 'src/components/v1/LMS/NavbarLMS/NavbarLMS';
@import 'src/components/v1/Card/GroupCard/GroupCard';
@import 'src/components/v1/Card/GroupCard/GroupCard';
@import 'src/components/v1/CollapseTutor/CollapseTutor';
@import 'src/components/v1/LocalLoading/LocalLoading';
@import 'src/components/v1/PanelSidebar/PanelSidebar';
@import 'src/components/v1/PanelContent/PanelContent';
@import 'src/components/v1/Question/Feedback/Feedback';
@import 'src/components/v1/NewGroupModal/NewGroupModal';
@import 'src/components/v1/Card/MinimalCard/MinimalCard';
@import 'src/components/v1/SearchDropdown/SearchDropdown';
@import 'src/components/v1/AdvancedPopOver/AdvancedPopOver';
@import 'src/components/v1/PanelSidebarMenu/PanelSidebarMenu';
@import 'src/components/v1/AdvancedCheckbox/AdvancedCheckbox';
@import 'src/components/v1/RenderStatusDate/RenderStatusDate';
@import 'src/components/v1/ChatComponents/InputChat/InputChat';
@import 'src/components/v1/popover/FilterPopover/FilterPopover';
@import 'src/components/v1/AdvancedAccordion/AdvancedAccordion';
@import 'src/components/v1/UI/Molecules/ReviewTutor/ReviewTutor';
@import 'src/components/v1/UI/Molecules/DragAndDrop/DragAndDrop';
@import 'src/components/v1/SatisfactionSurvey/PanelSatisfactionSurvey/PanelSatisfactionSurvey';
@import 'src/components/v1/SatisfactionSurvey/SatisfactionSurveyQuestion/SatisfactionSurveyQuestion';
@import 'src/components/v1/PanelContentHeader/PanelContentHeader';
@import 'src/components/v1/PanelContentTopBar/PanelContentTopBar';
@import 'src/components/v1/popover/ProfilePopover/ProfilePopover';
@import 'src/components/v1/Question/AnswerQuestion/AnswerQuestion';
@import 'src/components/v1/UI/Molecules/FilterSearch/FilterSearch';
@import 'src/components/v1/PopoverNotification/PopoverNotification';
@import 'src/components/v1/LMS/HomeComponents/AllCourses/AllCourses';
@import 'src/components/v1/ChatComponents/Conversation/Conversation';
@import 'src/components/v1/CourseTest/CourseTestObjectiveQuestion/CourseTestObjectiveQuestion';
@import 'src/components/v1/CourseTest/CourseTestUploadQuestion/CourseTestUploadQuestion';
@import 'src/components/v1/CourseTest/PanelCourseTest/PanelCourseTest';
@import 'src/components/v1/CourseTest/PanelCourseTestTutor/PanelCourseTestTutor';
@import 'src/components/v1/ContentComponents/StatusLabel/StatusLabel';
@import 'src/components/v1/Question/ObjectiveQuestion/ObjectiveQuestion';
@import 'src/components/v1/ChatComponents/ContactProfile/ContactProfile';
@import 'src/components/v1/PanelContentBreadcrumb/PanelContentBreadcrumb';
@import 'src/components/v1/ContentComponents/LockedContent/LockedContent';
@import 'src/components/v1/ContentComponents/RenderContent/RenderContent';
@import 'src/components/v1/ContentComponents/EmptyContent/EmptyContent';
@import 'src/components/v1/Question/DiscursiveQuestion/DiscursiveQuestion';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarContent';
@import 'src/components/v1/CourseDetailsBackground/CourseDetailsBackground';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarContent';
@import 'src/components/v1/ContentComponents/StatusDeadline/StatusDeadline';
@import 'src/components/v1/ChatComponents/MessageContainer/MessageContainer';
@import 'src/components/v1/ContentComponents/ActivitySection/ActivitySection';
@import 'src/components/v1/ContentComponents/ActivitySection/Activity/Activity';
@import 'src/components/v1/ContentComponents/NavigationButtons/NavigationButtons';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarCard/SideBarCard';
@import 'src/components/v1/ContentComponents/ActivitySectionTutor/ActivitySectionTutor';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarDropdown/SideBarDropdown';
@import 'src/components/v1/ContentComponents/ActivitySection/Activity/AnswerActivity/AnswerActivity';
@import 'src/components/v1/ContentComponents/SideBarContent/SidebarDropdownTaskStatus/SidebarDropdownTaskStatus';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarDropdownFinalTasks/SideBarDropdownFinalTasks';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarDropdown/SideBarDropdownClass/SideBarDropdownClass';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarDropdown/SideBarDropdownCheck/SideBarDropdownCheck';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarDropdown/SideBarActivityMarker/SIdeBarActivityMarker';
@import 'src/components/v1/ContentComponents/SideBarContent/SideBarDropdownFinalTasks/SidebarDropDownFinalTaskItem/SidebarDropDownFinalTaskItem';
@import 'src/components/v1/Tabs/Tabs';
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;