.modal-send-report-students {
  &__title {
    color: @primary-color-brand;
    font-size: @font-size-extreme;
    font-weight: bold;
  }

  &__subtitle {
    margin-top: 2rem;
    font-size: @font-size-extreme;
  }

  &__label {
    margin-top: 2rem;
    font-size: @font-size-large;
  }

  &__feedback-success {
    margin-top: 2.5rem;

    &__description {
      font-size: @font-size-big;
    }
  }

  &__footer {
    margin-top: 4rem;
    gap: 1rem;
    justify-content: end;
  }
}