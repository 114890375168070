.content {
    padding: 50px;
    width: 100%;
    height: 100%;
    gap: 20px;

    &__font-style {
        font-size: @font-size-larger;
        font-weight: bold;
    }

    &__columns {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.button-layout {
    display: flex;
    justify-content: end;
    gap: 20px;
}

.date-gap {
    gap: 20px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
a, area, button, [role='button'], input:not([type='range']),
label, select, summary, textarea 
.ant-input:placeholder-shown .ant-picker {
    align-items: center;
    border-radius: 7px;
}

@media screen and (max-width: 640px) {
    .content {
        padding: 0;
    }

    .align-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .date-gap {
        grid-gap: 0;
    }
}