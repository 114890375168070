.status-label-container{
  width: 100%;
  
  &__status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;

    &__icon {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      margin-right: 10px;

      &--waiting {
        background-color: @primary-orange;
      }

      &--done {
        background-color: @primary-green;
      }

      &--redo {
        background-color: @secondary-red;
      }
    }

    &__label {
      margin-bottom: 0;
      font-size: 16px;

      &--waiting {
        color: @primary-orange;
      }

      &--done {
        color: @primary-green;
      }

      &--redo {
        color: @secondary-red;
      }
    }
  }
}