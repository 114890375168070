.groups-page {
    margin-top: -3.154rem;

    &__title-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: @font-size-large;

        &__text {
            font-weight: bold;
        }
    }

    &__title {
        font-size: @font-size-largest;
        color: @primary-color-lms;
        font-weight: bold;
    }

    &__subtitle {
        font-size: @font-size-extreme;

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__bold {
            font-weight: bold;
        }

        &__closed {
            font-size: @font-size-extreme;
            font-weight: bold;
            color: @gray-text-secondary;
        }
    }

    &__center {
        font-weight: 500;
    }

    &__banner {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        margin-top: 1rem;
    }

    &__banner-modal {
        width: 100%;
        height: 10rem;
        object-fit: cover;
    }
}

.gap {
    gap: 2.5rem;
}

.container_table {
    width: 92%;
    margin: 0 auto;
    overflow-x: scroll;
    padding-bottom: 5rem;

    &__buttonRemove {
        display: flex;
        margin-top: 1rem;
        justify-content: flex-start;
    }

    &__detailsElective {
        text-decoration: underline;
        cursor: pointer;
    }
}

.container_title {
    width: 95%;
    margin: 0 auto;
}

.ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: none;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
    background: none;
}

.ant-table-thead>tr>th {
    font-weight: bold;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color-lms;
    border-color: @primary-color-lms;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: @primary-color-lms;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: @primary-color-lms;
}

.modal-status-elective {
    &__banner {
        width: 100%;
        height: 9.25rem;
        object-fit: cover;
    }

    &__container {
        width: 90%;
        margin: 0 auto; 
    }

    &__title {
        font-size: @font-size-largest;
        font-weight: bold;
    }

    &__subtitle {
        font-size: @font-size-larger;

        &__bold {
            font-weight: bold;
            font-size: @font-size-larger;
        }

        &__small {
            font-size: @font-size-large;

            &__bold {
                font-size: @font-size-large;
                font-weight: bold;
            }
        }
    }

    &__table {
        margin-left: -15px;
    }
}

.modal-close-group {

    &__spacing {
        width: 90%;
        margin: 0 auto;
    }

    &__container {
        border: 3px solid @primary-color-lms;
        border-radius: 8px;
        width: 85%;
        height: auto;
        padding: 1.25rem 1.25rem;
        margin: 1.25rem auto;
    }

    &__title {

        &__color {
            font-size: @font-size-extreme;
            color: @primary-color-lms;
            font-weight: bold;
        }

        &__bold {
            font-size: @font-size-largest;
            font-weight: bold;
        }
    }

    &__subtitle {
        font-size: @font-size-large;

        &__color {
            font-size: @font-size-large;
            color: @primary-color-lms;
            font-weight: bold;
        }

        &__bold {
            font-size: @font-size-large;
            font-weight: bold;
        }
    }

    &__gap {
        gap: 1.25rem;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: end;
    }
}

.filter {
    &__icon {
      cursor: pointer;
      color: @primary-color-lms;
    }
}

.status {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .column-responsive {
        display: flex;
        flex-direction: column;
    }

    .groups-page {
        &__center {
            gap: 0;
            text-align: center;
        }
    }

    .btn-responsive {
        display: flex;
        justify-content: center;
        gap: 0.65rem;
    }

    .text-align {
        text-align: center;
        margin-bottom: 0.95rem;
    }
}

.certificate-icon {
    cursor: pointer;
    color: @primary-color-lms;
}