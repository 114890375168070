.sidebar-dropdown {
  width: 100%;
  margin-top: 2rem;

  &__summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

    &__module {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        display: flex;
        flex-direction: column;

        &__header {
          font-size: 16px;
          line-height: 90%;
        }
        &__title {
          font-weight: 600;
        }
      }
    }

    &__progress {
      color: @primary-green;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      &--completed {
        font-weight: 700;
      }
    }
  }

  &__details-container {
    width: 100%;
    position: relative;
  }
}

.sidebar-dropdown .MuiPaper-rounded {
  border: none !important;
}

.sidebar-dropdown .MuiPaper-root {
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;

  .MuiCollapse-entered {
    width: 100%;
  }
}

.sidebar-dropdown .MuiAccordionDetails-root {
  padding: 0;
}

.sidebar-dropdown .MuiButtonBase-root {
  max-width: @sidebar-container-widht;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .sidebar-dropdown {
    width: 100%;
    margin-top: 6rem;
  }
}