.collapse-container {
  background-color: white;
  margin-bottom: 10%;

  &__panel {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: @radius-medium !important;
    box-shadow: @shadow-default;
    font-size: @font-size-larger;
    font-weight: bold;
  }
}