
.input-attachment-container {
  width: 100%;
  height: 85%;
  align-items: center;

  &__title {
    font-size: @font-size-larger;
    font-weight: bold;
  }

  &__subtitle {
    font-size: @font-size-smallest;
  }

  &__draggable_container {
    width: 100%;
    height: 100%;
    height: 9.25rem;
    background-color: @background-drag;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-grow: 1;

    &--disabled {
      cursor: not-allowed;
    }

    &__notUploading {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        max-width: 20rem;
        padding-top: 0.5rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: @gray600;
        font-weight: 200;
      }
    }
  }

  &__imgUploading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    &__img {
      width: 7rem;
      height: 7rem;
      object-fit: cover;
      border-radius: @radius-default;
      margin-right: 1rem;
    }

    &__info {
      max-width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;

      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__nameFile {
          width: 16rem;
          max-width: 20rem;
          margin-bottom: 0.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          font-weight: bolder;
        }
      }
    }
  }
}
