.navigation-buttons {
  width: 100%;
  display: flex;
  background-color: @header-background;

  &__button-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__button {
      display: flex;

      &--disabled {
        color: @text-gray;
        cursor: not-allowed !important;
      }

      &__icon {
        width: 25px;
        height: 25px;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid @primary-color-lms;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;

        svg {
          width: 15px;
          height: 15px;
          color: @primary-color-lms;
        }

        &--disabled {
          border-color: @text-gray;

          * {
            color: @text-gray;
          }
        }
      }

      &__label {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .navigation-buttons {
    &__button-container {
      height: @navigation-button-heigth;
      &__button {
        &__icon {
          width: 20px;
          height: 20px;
          padding: 10px;

          svg {
            width: 10px;
            height: 10px;
          }
        }

        &__label {
          font-size: 14px;
        }
      }
    }
  }
}
