.sidebar {
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &__hide-icon-container {
      &__button {
        cursor: pointer;
        color: @primary-color-lms;

        &__icon {
          width: 25px;
        }
      }
    }

    &__card {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__dropdown-list {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;

      &__final-test-container {
        width: 100%;

        &__test {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 15px 16px;

          &--selected {
            background-color: @primary-color-lms;
          }

          &__title {
            &--selected {
              color: #fff;
              font-weight: 600;
            }
          }
        }

        &--disabled {
          background-color: @gray-300;
          cursor: not-allowed !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    max-width: 100vw;

    &__container {
      max-width: 100vw;
      width: 100%;

      &__hide-icon-container {
        display: none;
      }

      &__card {
        width: 450px;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .sidebar {
    &__container {
      &__card {
        width: 100%;
        height: 290px;
      }
    }
  }
}
