.render-content {
  width: 100%;
  height: calc(100vh - @navigation-button-heigth);
  overflow-y: auto;

  &__render-pdf {
    &__divider {
      height: 3px;
      width: 100%;
      color: #741982;
      background-color: #741982;
    }
  }

  &__html-render {
    width: 100%;
    height: 100%;
  }

  &__audio-render {
    height: 100%;

    &__container-card {
      display: flex;
      justify-content: center;
    }

    &__card {
      margin-top: 2rem;
      height: 18rem;
      width: 25rem;
      border-radius: 5px;
      align-items: center; 
      display: flex;
      flex-direction: column;
      border: 1px solid #CFD4D2;
      box-shadow: 0px 3.78947px 3.78947px rgba(0, 0, 0, 0.25);
    }

    &__icon {
      height: 62%; 
      margin-top: -1.5rem ;
    }

    &__player {
      width: 100%;
      height: 46%; 
      background-color: #F1F1F1;
      padding: 2.5rem;
    }

    &__description--sidebar-hide {
      padding: 2rem;
      width: 100%;
      transition: all 1s
    }
    
    &__description {
      width: calc(95vw - @sidebar-container-widht);
      padding: 2rem;
      transition: all 1s
    }
  }

  &__render-text {
    margin: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .render-content {
    &__audio-render {
      height: 100%;

      &__card {
        height: 17rem;
        width: 100%;
        margin-bottom: 4rem;
      }

      &__description {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}

.download-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;

  a {
    height: auto;
  }
}



.loading-pdf {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
}
