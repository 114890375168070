.profileImg-container {
  display: flex;
  width: 43px;
  height: 43px;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: @radius-circle;
  background-color: @white;
  object-fit: cover;

  &__image {
    color: @primary-color-brand;
    width: 20px;  

    &--user-icon {
      padding: 2px;
      width: 45px;
      height: 45px;
      border-radius: @radius-circle;
    }
  }
}