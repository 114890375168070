.satisfaction-survey-question-container {
  margin-top: 2rem;

  &__title {
    margin-bottom: 1.25rem;
  }

  &__descriptive {
    margin-bottom: 1rem;
  }

  &__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__content {
      display: flex;
      width: 100%;
      border: 87931px solid @primary-color-lms;
      padding: 0.5rem;
      border-radius: 4px;
    }

    &__label {
      height: 100%;
      margin-left: 10px;
      margin-bottom: 0;
      display: flex;

      &__option {
        margin-right: 8px;
      }
    }
  }
}