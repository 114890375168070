.molecules-tabs {
  &__items {
    width: 100%;
    display: flex;
    margin-bottom: 1.5rem;
  }

  &__item {
    padding: 0 1.5rem 0.5rem 1.5rem;
    cursor: pointer;

    &__label {
      font-size: 18px;
    }
  }
}

.molecules-tabs--primary {
  &__items {
    border-bottom: 1px solid white;
  }

  &__item {
    &__label {
      color: white
    }
  }

  &__item--active {
    border-bottom: 3px solid white;

    &__label {
      font-weight: bold;
    }
  }

  &__body {
    color: white
  }
}

.molecules-tabs--secondary {
  &__items {
    border-bottom: 1px solid @primary-color-lms;
  }

  &__item {
    &__label {
      color: @primary-color-lms
    }
  }

  &__item--active {
    border-bottom: 3px solid @primary-color-lms;

    &__label {
      font-weight: bold;
    }
  }

  &__body {
    color: @primary-color-lms
  }
}