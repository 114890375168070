.content-container {
  width: 100%;
  display: flex;

  &__sidebar {
    width: @sidebar-widht;
    min-width: @sidebar-container-widht;
    transition: 0.8s;
    display: table-cell;
    overflow-y: auto;
    max-height: calc(100vh - @header-heigth - @navbar-lms);

    &--hide {
      width: @sidebar-container-widht;
      margin-left: -@sidebar-container-widht;
    }
  }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - @header-heigth - @navbar-lms);
    flex-grow: 1;

    &__show-sidebar-container {
      display: flex;
      justify-content: flex-start;
      padding: 15px;
      position: fixed;
      margin-top: 10px;
      z-index: 1;

      &__button {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: @primary-color-lms;

        &__icon {
          width: 25px;
          transform: rotate(180deg);
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content-container {
    &__main {
      display: none;
    }

    &__sidebar {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .content-container {
    &__main {
      display: none;
    }

    &__sidebar {
      width: 100vw;
      min-width: 100%;
      overflow-y: unset;
    }
  }
}