.cardGroup-container {
  min-width: 282px;
  min-height: 320px;
  width: 282px;
  height: 320px;
  position: relative;
  box-shadow: @shadow-big;
  border-radius: @radius-default; 

  &__see-details {
    text-decoration: underline;
    color: @primary-color-lms;
    cursor: pointer;
  }

  &__image-card {
    height: 190px;
    position: relative;
    background: linear-gradient(360deg, #636363d1 10%, rgba(78, 77, 77, 0.149) 60%);
    border-radius: 10px 10px 0 0;
 
    &__title {
      bottom: 20px;
      position: absolute;
      font-size: 25px;
      font-weight: bold;
      color: #fff;
      line-height: 110%;
      width: 85%;
    }
  }

  &__background-image {
    position: relative;
    width: 100%;
    max-height: 190px;
    object-fit: cover;
    border-radius: @radius-default @radius-default 0 0;
    border-radius: 10px 10px 0px 0px;
    z-index:-1;
    height: 200px;

    &__title {
      width: 100%;
      padding: 1rem;
      bottom: 0;
      position: absolute;
      font-size: 1.6vh;
      font-weight: bold;
      color: #fff;
      line-height: 100%;
    }
  }

  &__description {
    height: 5rem;
    padding: 1rem;

    &__info {
      font-size: @font-size-smallest;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      border: 2px solid transparent;
    }

    &__button-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: underline;
        color: @primary-color-lms;
      }
    }
  }
}

.space-between-icons {
  gap: 8px;

  &__align-buttons {
    display: flex;
    align-items: center;
    color: @primary-color-lms;
  }
}

.icon-container  {
  position: absolute;
  top: 20px;
  margin-left: 15px;
  width: 90%;

  &__between-justified {
    justify-content: space-between;
    display: flex;
    align-items: center;

    &__pendencies-icon {
      position: absolute;
      left: 2px;
      font-size: 12px;
      font-weight: 600;
    }

    &__text-icon {
      position: absolute;
      left: 36px;
      top: 18px;
      font-size: 11px;
      font-weight: 600;
    }

    &__hours-accounted {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
    }
  }

  &__book-icon {
    color: @primary-color-lms;
  }
}

@media screen and (max-width: 992px) {
  .card-container {
    min-width: 282px;
    min-height: 320px;
    max-width: 282px;
    max-height: 320px;
  }
}