.review-tutor {
  width: 100%;
  font-weight: normal;
  font-size: @font-size-small;

  &__answerStudent {
    font-size: 16px;
    text-align: left;
    font-weight: bold;
  }

  &__radio-group {
    margin: 1rem 0 1rem 0;

    &__radio {
      min-width: 114px;
      height: 48px;
      padding: 1rem;
      margin: 0 1rem 1rem 0;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background-color: white;
    }
  }

  &__expected-answer {
    color: @answer-color-lms;
  }

  &__button {
    margin-top: 2rem;
    color: @gray600;
    text-decoration: underline;
  }
}

.input-textarea-container {
  width: 100%;
  height: 134px;
  margin: 1rem 0 1rem 0;

  &__textarea {
    resize: none;
    width: 100%;
    height: 100%;
    border: 1px solid @secondary-gray;
    padding: 10px;

    &--required {
      border: 2px solid @primary-red;
    }
  }

  &__characters {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 720px) {
  .answer-question-container {
    &__review-tutor {
      margin-bottom: 5rem;
    }
  }
}