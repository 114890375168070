.panel-course-test-tutor {
  &__solution {
    padding: 0 2rem 2rem 2rem;

    &__title {
      font-size: 30px;
      color: @primary-color-lms;
      font-weight: 600;
    }
  }
}