.course-details-background {
  position: relative;
  background-color:@primary-color-lms;
  width: 100%;
  overflow: hidden;
  
  &__bottom-shape{
    position: absolute;
    bottom: -150px;
    width: 100%;
  
  }

  &__red_bottom_shape{
    position: absolute;
    bottom: -50px;
    width: 300px;
    height: 300px;
  }

  &__blue_right_shape{
    position: absolute;
    top: 20%;
    right: -90px;
    width: 300px;
    height: 300px;
  }
}
