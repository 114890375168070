.my-groups {
  padding: 0 0 2rem 7rem;
  margin: 1rem;
  width: 95%;

  &__tab-color {
    color: @primary-color-lms;
  }

  &__title {
    font-weight: bold;
    font-size: @font-size-largest;
    color: @primary-color-lms
  }

  &__subtitle {
    font-weight: 400;
    font-size: @font-size-large;
    width: 95%;
  }

  &__group-content {
    margin: 2rem 0 4rem -1rem;
    overflow: hidden;
  }

  &__group-list {
    width: 100%;
    max-width: 1360px;
    height: 55%;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    &__inner {
      height: auto;
      max-width: 1360px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 2.8125rem 0;
      margin-bottom: .3125rem;

      >div {
        margin: 0 1rem;
      }
    }

    &__empty-container {
      text-align: center;

      &__image {
        max-width: fit-content;
      }

      &__title {
        color: @primary-color-lms;
        font-size: @font-size-largest;
        font-weight: bold;
      }
      &__subtitle {
        width: 19.5rem;
        text-align: center;
      }
    }
  }

  .tab-layout {
    display: flex;
    width: 98%;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__wrapper {
      width: 70%;
    }

    &__tab-color {
      color: @primary-color-lms;
    }

    &__divider-wrapper {
      width: 100%;
      height: 1px;
      background-color: @primary-color-lms;
    }
  }
}

.collapse-videos {
  background-color: white;

  &__panel {
    width: 100%;
    margin: 0 1rem 1rem 0rem;
    font-size: @font-size-largest;
    font-weight: bold;
  }
}

.carousel-content {
  gap: 1.875rem;
  display: flex;
  flex-direction: row;

  width: auto;
  height: 11.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .my-groups {
    padding-left: 1rem;
    padding-top: 0;

    &__title {
      font-size: @font-size-larger;
    }

    &__group-list {
      max-width: 335px;
      overflow-y: hidden;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */

      &__inner {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
      }

      &__empty-container {
        &__title {
          font-size: @font-size-larger;
        }
      }
    }
  }

  .tab-layout {
    flex-direction: column-reverse;
    gap: 1.25rem;

    &__divider-wrapper {
      width: 80% !important;
      position: absolute;
    }
  }

  .collapse-videos {
    &__panel {
      font-size: @font-size-larger;
    }
  }
}

@media screen and (max-width: 600px) {
  .my-groups {
    padding-top: 0rem;
  }
}