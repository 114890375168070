.answer-activity-container {
  width: 100%;
  max-width: 600px;
  height: 300px;
  background-color: @gray-300;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &__navigation-bar {
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      display: flex;
      list-style-type: none;
      margin: 0;
      width: 100%;
      padding: 0;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 10px 0;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transition: 0.5s;

        &--selected {
          background-color: @primary-color-lms;
          color: #fff;
        }
      }
    }
  }
  &__input-textarea-container {
    width: 100%;
    height: 85%;
    padding: 20px;

    textarea {
      resize: none;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      border: 1px solid @secondary-gray;
    }
  }

  &__input-attachment-container {
    width: auto;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 20px;

    &__draggable_container {
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      border-radius: 15px;
      margin: 10px 0;
      flex-grow: 1;

      &__icon {
        width: 40px;
        height: 40px;
        display: flex;
      }

      &__label{
        width: 60%;
        text-align: center;
      }
    }
  }
}
