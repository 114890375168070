.satisfaction-survey-container {
  width: 100%;
  padding: 40px;
  min-height: calc(100vh - @header-heigth - @navigation-button-heigth);
  overflow-y: auto;

  &__title {
    font-weight: 700;
    color: @primary-color-lms
  }
}