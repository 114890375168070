.searchBar-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: @gray-bg;
  border: 1px solid @gray300;
  border-radius: @radius-small;
  flex: 1;
  width: 100%;
  height: 50px;

  &__icon {
    font-size: 15px;
    margin: 0 15px;
  }

  &__input {
    width: 100%;

    &__content {
      background-color: @gray-bg;
      border: 0; 
      text-align: 'left';

      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
}


