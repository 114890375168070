.navbar-lms {
  width: 100%;
  height: @navbar-lms;

  &--remove-margin {
    margin-top: 0;
  }

  &__course-content {
    &__teacher {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__button-wrapper {
        display: flex;
      }
    }
  }

  &__course-list {
    margin: 0 auto;
    position: relative;

    &__inner {
      display: flex;
      justify-content: space-evenly;
      gap: 1rem;
    }

    &__empty-container {
      text-align: center;

      &__image {
        max-width: fit-content;
      }

      &__title {
        color: @primary-color-lms;
        font-size: @font-size-largest;
        font-weight: bold;
      }
    }
  }

  &__menu {
    min-height: @navbar-lms;
    margin: 0 auto;
    padding: 0.5rem 8rem 0.5rem 8rem;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    box-shadow: @shadow-big;

    &::-webkit-scrollbar {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &__icon {
        color: @primary-color-lms;
      }
    }

    &__link {
      text-decoration: underline;
      cursor: pointer;
      color: @primary-color-lms;
      white-space: nowrap;
    }
  }

  &__teacher {
    min-height: @navbar-lms;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: @shadow-big;
    padding: 0.5rem 8rem 0.5rem 8rem;

    &__tutorContent {
      display: flex;
      font-size: @font-size-large;
      font-family: 'Raleway';
      font-weight: 700;
      text-transform: uppercase;
      color: @primary-color-lms;
    }
  }
}

a:hover {
  color: @secondary-color-lms;
}

.popover-container {
  width: 290px;
  padding-left: 1.5rem;
  max-height: 30rem;
  overflow: auto;

  &__button {
    width: 11.25rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @radius-small;
    background-color: none;
    border: 1px solid @primary-color-lms;
    font-weight: bold;
    color: @primary-color-lms;
  }

  &__title-container {
    margin-bottom: 1rem;

    &__title {
      font-size: @font-size-small;
      color: @primary-color-lms;
    }

    &__subtitle {
      font-size: @font-size-smallest;
      color: @primary-color-lms;
    }
  }

  &__content {
    &__list {
      margin-bottom: 1.375rem;
    }
  }
}
@media screen and (max-width: 1200px) {
  .navbar-lms {
    &__menu {
      margin: 0 0 3rem 0;
    }
  }
}

@media screen and (max-width: 912px) {
  .navbar-lms {
    &__menu {
      margin: 0 0 3rem 0;

      &__link {
        margin-left: 2rem;
        padding-right: 2rem;
      }
    }

    &__course-content {
      width: 100%;

      &__teacher {
        display: flex;
        justify-content: space-between;
      }
    }

    &__course-list {
      &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
      }

      &__empty-container {
        &__title {
          font-size: @font-size-larger;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar-lms {
    &__menu {
      padding: 0.5rem 4rem 0.5rem 4rem;
    }

    &__teacher {
      padding:  0.5rem 4rem 0.5rem 4rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar-lms {
    &__menu {
      padding: 0.5rem 2rem 0.5rem 2rem;
    }

    &__course-content {
      &__teacher {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .navbar-lms {
    &__teacher {
      padding: 1rem 1rem 1rem 1rem;
    }

    &__course-content {
      &__teacher {
        &__button-wrapper {
          width: 100%;
          overflow: hidden;
          overflow-x: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          align-items: center;
      
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}