.transparent-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: 0 !important;
  width: auto;
  height: auto;

  &:disabled {
    cursor: default;
  }
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  border-top: 1px solid @border-color;
  padding-top: 30px;

  &__single {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
  color: @primary-color-lms !important;
}

.MuiTab-wrapper {
  font-size: 15px !important;
  font-weight: 600 !important;
  text-transform: none;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.PrivateTabIndicator-colorSecondary-3,
.PrivateTabIndicator-root-1,
.PrivateTabIndicator-colorSecondary-5,
.jss5 {
  height: 3px !important;
  background-color: @primary-color-lms !important;
}

html,
body,
#root {
  height: calc(100% - @header-heigth);
}
button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.row {
  margin: 0;
}
.col {
  margin: 0;
}
.container-fluid {
  padding: 0;
}

//-=-=-=-=- custom scrollbar style -=-=-=-=-=-
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background: #797979;
}

.ant-collapse-header{
  color: @primary-color-lms !important;
}

.ant-input {
  border: 1px solid #d9d9d9
}