.profile-container {
  background-color: @white;
  max-height: 100vh;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__header-container {
    background-color: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    height: 80px;
    z-index: 1;

    &__header-back {
      display: flex;
      align-items: center;
      border: 0;
      background: transparent;

      &__header-back-icon {
        font-size: 1.5vw;
        font-weight: 500;
        margin-right: 1vw;
      }

      &__header-back-text {
        margin: 0;
        font-weight: 700 !important;
        font-size: 18px;
        margin-left: 16px;
      }
    }
    &__header-faq {
      height: 24px;
    }
  }

  &__profile-content {
    background-color: @primary-gray;
    min-height: 100vh;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 80px;

    &__user-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      width: 180px;
      cursor: pointer;

      &__user-avatar-img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-bottom: 8px;
      }

      &__user-avatar-input {
        display: none;
      }

      &__user-avatar-label {
        margin-top: 8px;
        cursor: pointer;
        color: @text-gray;
      }
    }
    &__user-info-row {
      width: 100%;

      &__profile-label {
        font-weight: 500;
        font-size: 14px;
        color: @text-gray;
      }

      &__profile-data {
        font-weight: 500;
        font-size: 16px;
      }

      & + div {
        margin-top: 8px;
      }
    }

    &__change-password-button {
      width: 100%;
      height: 56px;
      border-radius: 4px;
      background-color: @primary-color-lms;
      color: @white;
      border: 0;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .profile-container {
    &__profile-content {
      background-color: @white;
      max-width: 100%;
    }
  }
}


.modal-password-container{
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  &__input{
    margin: 10px 0;
    border: 1px solid @primary-color-lms;
    border-radius: 10px;
    padding: 10px;
  }
}