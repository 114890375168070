.panel-content-top-bar {
  background-color: @primary-color-brand;
  height: @header-heigth;
  width: 100%;
  z-index: 2;
  padding: 0 19.5rem 0 19.5rem;

  &__navbar-container {
      display: flex;
      align-items: center;

      &--hidden-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end; 
      }

    &__buttons {
      height: 2.5rem;
      margin-right: 3.5rem;
      display: flex;
      align-items: center;
      color: #fff;
      gap: 0.5rem;
      font-weight: 600;
      font-size: @font-size-smaller;
    }

    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #fff;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }

    &__notification-indicator {
      width: 0.56rem;
      height: 0.56rem;
      border-radius: @radius-circle;
      background-color: @indicator-red;
      position: relative;
      top: -0.1rem;
      left: 0.55rem;
    }
  }

  &__menu {
    width: 60%;
    color: @white;

    &__dropdown-menu {
      display: block;

      &__icon {
        color: #fff;
        width: .875rem;
        height: .875rem;
        cursor: pointer;
      }
    }
  }

  &__logo {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;

    &--tecer {
      width: 100px;
    }
  }

  &__right {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__portal-logo {
      height: 50px;
    }
    
    &__icon {
      &:extend(.icon);

      &__chat {
        &:extend(.icon);
      }

      &__help {
        &:extend(.icon);
      }

      &__block {
        &:extend(.icon);
      }

      &__button {
        position: relative;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }

      &__notification-alert {
        position: absolute;
        background-color: @primary-red;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        right: -4px;
        top: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        color: #fff;
      }
      &__image {
        color: @primary-color-brand;
        width: 20px;

        &--user-icon {
          padding: 2px;
          width: 45px;
          height: 45px;
          border-radius: @radius-circle;
          object-fit: cover;
        }
      }
    }
  }
}

.product-list-wrapper {
  margin-top: 1rem;
  border-bottom: 1px solid @white;

  &__product-name {
    font-size: @font-size-large;
    color: @white;
  }
}

.notification-modal {
  &__notification {
    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &__item {
      width: 100%;
      border-bottom: 1px rgba(0, 0, 0, 0.4) solid;

      &__title {
        width: 100%;
        display: block;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 400;
        margin-top: 5px;
      }

      &__description {
        width: 100%;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 200;
        line-height: 1rem;
      }
    }
  }
}

.panel-content-top-bar__menu .MuiTabs-flexContainer {
  justify-content: space-between;
}

.hamburguer-menu {
  background-color: @primary-color-brand;
  border: 1px solid @white;
  border-radius: .4375rem;
  width: 18rem;
  min-height: 20rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.hiddenAvatar {
  display: none;
}

.icon {
  display: flex;
  width: 43px;
  height: 43px;
  padding: 0 10px;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: @radius-circle;
  background-color: @white;
}

@media screen and (max-width: 2000px) {
  .panel-content-top-bar {
    &__left,
    &__right {
      width: 150px;
    }
  }
}

@media screen and (max-width: 1635px) {
  .panel-content-top-bar {
    padding: 0 4.5rem 0 11rem;
  }
}

@media screen and (max-width: 1024px) {
  .panel-content-top-bar {
    padding: 0 3.5rem 0 8rem;
  }
}

@media screen and (max-width: 640px) {
  .panel-content-top-bar {
    padding: 0 2rem 0 1rem;

    &__right {
      width: 100px;

      &__icon {

        &__help {
          display: none;
        }

        &__block {
          display: none;
        }
      }
    }

    &__menu {
      &__appbar {
        display: none !important;
      }
    }

    &__navbar-container {
      width: 100%;
      font-size: @font-size-smaller;
      margin-left: .3125rem;
      gap: .5rem;

      &__buttons {
        margin-right: 0;
      }
    }
  }

  .hidden {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .panel-content-top-bar {
    &__right {
      justify-content: flex-end;
      width: 25%;

      &__icon {
        &__chat {
          display: none;
        }
      }
    }
  }
}