.empty-content {
  width: 100%;
  height: calc(100vh - @navigation-button-heigth);

  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    width: 21rem;
    margin-right: 3rem;
  }

  &__title {
    font-size: 2rem;
    color: @primary-color-lms;
    font-weight: bold;

    margin-bottom: 1.5rem;
  }

  &__description {
    font-size: 1.25rem;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 10rem;
    height: 10rem;

    border-radius: 100%;
    background: #F6F6F6;
  }
}