:root {
  --primary-color-brand: #728B97;
  --secondary-color-brand: #B8C4CB;

  --primary-color-lms: #728B97;
  --secondary-color-lms: #B8C4CB;
  --answer-color-lms: #6E45E2;
}

@primary-color-brand: var(--primary-color-brand);
@secondary-color-brand: var(--secondary-color-brand);

@primary-color-lms: var(--primary-color-lms);
@secondary-color-lms: var(--secondary-color-lms);
@answer-color-lms: var(--answer-color-lms);

@background-light: #eee9ee;
@background-drag: #D5DCE0;
@border-color: #ffffff;
@gray-text: #919191;
@gray-text-secondary: #CAD3D8;
@gray-text-deadline: #939393;
@gray-text-light: #9b9aa3;
@gray-text-dark: #3e3e3e;
@gray-text-dark-title: #555555;
@gray50: #EDF0F2;
@gray-navbar: rgba(114, 139, 151, 0.2);
@gray300: #D5D5D5;
@gray600: #728B97;
@gray-bg: #f9f9f9;
@gray-bg-deadline: #dbdbdb;
@gray-bg-light: #e5e5e5ff;
@card-bg: #f6f6f6;
@white: #ffffff;
@light-pink-bg: #fbdddc;
@header-background: #fafbfc;
@indicator-red: #FF0000;
@primary-red: #ef4242;
@red-deadline: #e65c65;
@secondary-red: #ee4b2b;
@salmon: #FA8072;
@primary-yellow: #ffcf27;
@primary-gray: #f2f2f2;
@secondary-gray: #dfdfdf;
@gray-300: #f1f1f1;
@primary-purple: #dfb8e5;
@secondary-purple: #7e5a84;
@text-gray: #909090;
@primary-green: #74d98a;
@secondary-yellow: #e4f609;
@primary-orange: #ffa500;
@primary-blue: #0000CD;
@sidebar-selected: #b7b7b7;
@sidebar-disable: #e0e0e0;
@black-text-dark: #0c0c0c;
@done-parameters: #83FF6F;
@todo-parameters: #6F97FF;

//size parameters
@sidebar-widht: 32%;
@sidebar-container-widht: 420px;
@header-heigth: 4.5rem;
@navigation-button-heigth: 45px;
@bottom-scape: 80px;
@navbar-lms: 5rem;

// font
@font-size-big-extreme: 48px;
@font-size-extreme: 32px;
@font-size-largest: 24px;
@font-size-larger: 20px;
@font-size-big: 18px;
@font-size-large: 16px;
@font-size-regular: 15px;
@font-size-small: 14px;
@font-size-smaller: 13px;
@font-size-smallest: 12px;
@font-size-extra-small: 11px;

// border-radius
@radius-small: 6px;
@radius-default: 10px;
@radius-medium: 16px;
@radius-larger: 100px;
@radius-circle: 50%;

// shadows
@shadow-default: 0px 3px 2px rgb(215, 215, 215);
@shadow-big: 1px 3px 10px 0px rgb(215, 215, 215);