@import '@portal/styles/_parameters';

.popover-change-brand {
  &__content {
    min-height: 6rem;
    padding:  2.5rem;

    flex-direction: column;
    align-items: center;
  }

  &__list {
    flex-wrap: wrap;
    gap: 2rem;

    &__avatar {
      filter: grayscale(100%);
      transition: all 0.3s;
      background-size: 4rem;
      background-repeat: no-repeat;

      &:hover {
        filter: grayscale(0%);
        transform: scale(1.02);
      }
    }
  }

  &__logout {
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
    color: @primary-color-brand;
    font-size: 14px;
  }
}
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;