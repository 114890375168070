.card-container {
  min-width: 282px;
  min-height: 320px;
  width: 282px;
  height: 320px;
  position: relative;
  box-shadow: @shadow-big;
  border-radius: @radius-default;

  &__image-card {
    height: 190px;
    position: relative;
    background: linear-gradient(360deg, #636363d1 10%, rgba(78, 77, 77, 0.149) 60%);
    border-radius: 10px 10px 0 0;

    &__title {
      bottom: 20px;
      position: absolute;
      font-size: 25px;
      font-weight: bold;
      color: #fff;
      line-height: 110%;
      width: 85%;
    }
  }

  &__background-image {
    width: 100%;
    height: 190px;
    max-height: 190px;
    position: relative;
    z-index: -1;
    object-fit: cover;
    border-radius: @radius-default @radius-default 0 0;

    &__title {
      width: 85%;
      padding: 1rem;
      bottom: 0;
      position: absolute;
      font-size: 1.6vh;
      font-weight: bold;
      color: #fff;
      line-height: 100%;
    }

    &__progress-bar {
      width: 80%;
      position: absolute;
      top: 80%;
    }
  }

  &__description {
    height: 5rem;
    position: relative;
    padding: 1rem;
    font-size: @font-size-smallest;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    border: 2px solid transparent;

    &__content {
      min-height: 100%;
      font-size: @font-size-smallest;
      text-align: justify;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__gradient-label {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: @radius-default;
  }
}

.MuiButton-root {
  text-transform: none !important;
}

.ant-progress-text {
  color: #fff !important;
  font-size: 11px !important;
}

@media screen and (max-width: 992px) {
  .card-container {
    min-width: 282px;
    min-height: 320px;
    max-width: 282px;
    max-height: 320px;
  }
}
