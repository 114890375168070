.advanced-accordion {
  width: 100%;

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__icon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }

      &__title {
        color: @primary-color-lms;
        font-weight: 400;
        font-size: 16px;
      }

      &__pending-number {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: @primary-yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        font-weight: 500;
        color: #fff;
      }

      &__pending-text {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  &__divisor {
    width: 95%;
    height: 1.5px;
    background-color: #d3d3d3;
    border: none;
    margin: 0 auto;
  }

  &__pending-activity {
    width: 100%;
    margin-top: 20px;

    &__columns {
      width: 100%;
      display: flex;

      &__item {
        width: 50%;
        display: flex;
        justify-content: center;
      }
    }

    ul {
      padding-inline-start: 20px;
      margin-bottom: 0;

      li {
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 16px;

        strong.advanced-accordion__pending-activity__item {
          text-align: end;
          margin-left: 10px;

          &--available {
            font-weight: 700;
            color: #43a73a;
          }

          &--unavailable {
            font-weight: 700;
            color: @primary-red;
          }

          &--warning {
            font-weight: 700;
            color: @primary-yellow;
          }
        }
      }
    }

    ul:first-child {
      margin-right: 20px;
    }

    &__button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      &__button {
        background-color: transparent;
        padding: 4px 40px;
        border-width: 1px;
        border-style: solid;
        border-radius: 10px;
        border-color: @primary-color-lms;
        font-size: 16px;
        color: @primary-color-lms;
      }

      &__button:hover {
        cursor: pointer;
        color: #fff;
        background-color: @primary-color-lms;
        transition: 0.5s;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .advanced-accordion {
    &__summary {
      padding: 10px;
      &__item {
        &__title {
          color: @primary-color-lms;
          font-weight: 400;
          font-size: 14px;
        }

        &__pending-number {
          font-size: 14px;
        }

        &__pending-text {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .advanced-accordion {
    &__summary {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 5px;

      &__item {
        &:last-child {
          margin-top: 5px;
        }

        &__icon {
          margin-right: 5px;
        }

        &__title {
          color: @primary-color-lms;
          font-weight: 400;
          font-size: 12px;
          text-align: center;
        }

        &__pending-number {
          font-size: 12px;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          margin-right: 5px;
        }

        &__pending-text {
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}
