@import '@portal/styles/_parameters';

.card-broadcast {
  width: 9.5rem;
  height: 7rem;

  align-items: center;
  justify-content: start;
  flex-direction: column;

  gap: 0.5rem;
  padding: 0.75rem;

  border-radius: 0.625rem;

  background-color: @white;
  box-shadow: @shadow-default;

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(1.02);
  }

  &__icon {
    width: 4.125rem;
    height: 3rem;
    color: @primary-color-brand;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: @primary-color-brand;
  }
}
@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;