.sidebar-dropdown-final-task {
  width: 100%;

  &__label-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__status {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      border: 1px @primary-green solid;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      background-color: #fff;

      &__check {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: @primary-green;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__label {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.sidebar-dropdown-final-task .MuiPaper-rounded {
  border: none !important;
}

.sidebar-dropdown-final-task .MuiPaper-root {
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.sidebar-dropdown-final-task .MuiAccordionDetails-root {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.sidebar-dropdown-final-task .MuiButtonBase-root {
  max-width: @sidebar-container-widht;
  width: 100%;
}
.sidebar-dropdown-final-task .MuiCollapse-root{
  width: 100%;
}
