.video-content {
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  &__container {
    margin-top: 45px;
    width: 650px;
    height: 375px;

    &__react-player {
      display: flex;
      justify-content: center;
    }
  }

  &__text-container {
    width: 650px;
  }

  &__buttons-area {
    width: 650px;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .video-content {
    &__container {
      width: 100%;
      height: auto;
      margin-bottom: 50px;

      &__react-player {
        width: 100% !important;
      }
    }

    &__text-container {
      width: 100%;
    }

    &__buttons-area {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
  
.ant-modal-content {
  border-radius: 1.125rem;
}