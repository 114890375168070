@import '@portal/styles/_parameters';

.navbar {
  width: 100%;
  height: 4.5rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: @primary-color-brand;
  padding: 0;

  &__content {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10rem;
    max-width: 1980px;
  }

  &__options {
    width: 100%;
    justify-content: end;
    align-items: center;
  }

  &__circle-notification {
    height: 0.56rem;
    width: 0.56rem;
    border-radius: 50%;
    background-color: #FF0000;
    z-index: 10;
    margin-top: 0.25rem;
  }
}

@media screen and (max-width: 1000px) {
  .navbar {
    height: 4rem;

    &__content {
      padding: 0 1rem;
    }
  }
}


@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;