.home-page {
  &__emptyBanner {
    width: 100vw;
    height: 16.875rem;
    object-fit: cover;
  }

  &__navbar-container {
    margin-bottom: 3rem;
  }
}

.MuiPaper-rounded {
  border-color: @primary-color-lms  !important;
  border-width: 1px !important;
  border-style: solid !important;
  box-shadow: none !important;
}

.control-arrow {
  width: 50px;

  &::before {
    transform: scale(1.5);
  }
}

.home-details {
  width: 300vw;

  &__modal-container {
    margin: 0 -15px 0 -15px;

    &__banner {
      width: 100%;
      height: 8rem;
      object-fit: cover;
      background-color: #741982;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    &__title {
      font-weight: bold;
      font-size: @font-size-largest;
      margin-bottom: 0.5rem;
    }

    &__subtitle {
      font-size: @font-size-larger;
      margin-bottom: 0.5rem;
    }

    &__enrollmentDates {
      font-size: @font-size-larger;
      margin-top: 0.5;
      font-weight: bold;
    }

    &__bold {
      font-weight: bold;

      &__color {
        color: @gray-text;
        font-weight: bold;
      }
    }

    &__dataPicker {
      border-bottom: 1px solid black;
    }

    &__spacing {
      padding: 4rem;
    }

    &__cancel {
      text-decoration: underline;
      color: @primary-color-lms;
      font-weight: 600;
      cursor: pointer;
    }

    &__submit {
      padding-left: 1rem;
      padding-right: 1rem; 
      font-weight: 600;
    }
  }

  &__content {
    margin: 0.315rem 0.25rem;
  }

  &__btn-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5rem;
    gap: 2rem;
  }

  &__gap {
    gap: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .home-details {
    &__modal-container {
      &__spacing {
        padding: 0.5rem;
      }
  
      &__submit {
        padding-left: 0.5rem;
        padding-right: 0.5rem; 
      }
    }
  }

  .home-page {
    &__emptyBanner {
      height: 210px;
    }
  }

  .column-responsive {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &__reverse {
      flex-direction: column-reverse;
    }
  }
}