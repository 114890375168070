.all-courses {
  padding: 1rem 8rem 2rem 8rem;

  &__title {
    font-weight: bold;
    font-size: @font-size-largest;
    color: @primary-color-lms;
    margin-bottom: 1.5rem;
  }

  &__title-trail {
    font-weight: bold;
    font-size: @font-size-largest;
    color: @primary-color-lms;
  }

  &__filter-buttons {
    padding-left: 7.5rem;
    align-items: center;
    justify-content: start;
  }

  &__filter {
    margin-top: 3.15rem;
    margin-bottom: 4.125rem;
  }

  &__course-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;

    &__footer {
      margin-top: 1rem;
    }

    &__carousel-item {
      margin-right: 2.5rem;
    }

    &__empty-container {
      width: 100%;
      margin-bottom: 2rem;
      text-align: center;

      &__image {
        max-width: fit-content;
        margin-bottom: 1rem;
      }

      &__title {
        width: 70%;
        color: @primary-color-lms;
        font-size: @font-size-largest;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .all-courses {
    padding-left: 4rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .all-courses {
    padding: 0 0 2rem 0;

    &__title {
      padding: 0 1rem 0 1rem;
    }

    &__title-trail {
      &__icon {
        margin-left: 1rem;
      }
    }

    &__filter {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &__course-list {
      justify-content: center;

      &__footer {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      }

      &__empty-container {
        &__title {
          font-size: @font-size-larger;
        }
      }
    }
  }
}