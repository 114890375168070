.filter-search {
  margin: 0 auto 1rem 0;
  padding-left: 7.5rem;
  align-items: center;
  justify-content: start;

  &__input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: @gray-bg;
    border: 1px solid @gray300;
    border-radius: @radius-small;
    flex: 1;
    width: 18rem;
    height: 50px;

    &__mobile {
      display: flex;
      align-items: center;
      background-color: @gray-bg;
      border-radius: @radius-small;
      border: 1px solid @gray300;
      padding: 4px;
      flex: 1;

      input {
        background-color: @gray-bg;
        border: 0;
        text-align: 'left';
      }
    }

    input {
      background-color: @gray-bg;
      border: 0;
      text-align: 'left';
    }
  }
}

@media screen and (max-width: 600px) {
  .filter-search {
    margin: 0 auto 0 0;
    padding: 2rem;
  }
}