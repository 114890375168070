.popover-management{
  min-width: 10rem;
  justify-content: center;
  padding: 1rem 0.5rem;

  &__text{
    font-weight: 700;
  }
}

@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;