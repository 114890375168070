.filter-dropdown {
  padding: 0 10px;

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &__clear {
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      margin-right: 5px;
    }

    &__filter {
      flex: 1;
      align-items: center;
      justify-content: center;
      background: @primary-color-lms;
      color: @white;
      border-radius: 10px;
      padding: 5px 0;
      margin-left: 5px;
    }
  }
}
