.check-container {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 35px;
  border: 1px @primary-green solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: #fff;

  &--selected {
    border: 1px #fff solid;
  }

  &--disabled {
    border: 1px @secondary-gray solid;
  }

  &__check {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: @primary-green;
    display: flex;
    justify-content: center;
    align-items: center;

    &--warning {
      background-color: @secondary-yellow;
    }

    &--late {
      background-color: @salmon;
    }
  }
}
