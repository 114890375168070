.sidebar-dropdown-task-status {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  border: 1px @primary-green solid;
  align-items: center;
  margin-right: 10px;
  background-color: #fff;
  z-index: 1;

  &--with-status{
    border: none;
  }

  &__container {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &--done {
      background-color: @primary-green;
    }

    &--waiting {
      background-color: @primary-orange;
    }

    &--redo {
      background-color: @salmon;
    }
  }
}

.sidebar-dropdown-task-status-tutor {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: #878787;
  z-index: 1;

  &__count {
    color: #FFFF;
    font-size: 16px;
    font-weight: 700;
  }
}
