.question-container {
  margin-top: 30px;

  &__title {
    margin-bottom: 20px;
    display: block;

    &__label {
      font-size: 22px;
      margin-bottom: 0;
    }

    &__number-of-answers {
      font-size: 15px;

      &--warning {
        color: @secondary-red;
      }
    }
  }

  &__options {
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__label {
      height: 100%;
      margin-left: 10px;
      margin-bottom: 0;
      display: flex;
      font-size: 16px;

      &__option {
        margin-right: 8px;
      }

      &--correct {
        color: @primary-green;
      }

      &--wrong {
        color: @secondary-red;
      }

      span {
        font-weight: 600;
      }

      &__icon {
        margin-left: 6px;
        margin-bottom: 4px;

        &--info {
          margin-left: 10px;
          svg {
            width: 20px;
            height: 20px;
            color: @text-gray;
          }
        }
      }
    }
  }

  &__input-textarea-container {
    width: 100%;
    height: 134px;
    margin: 1rem 0 1rem 0;

    &__textarea {
      resize: none;
      width: 100%;
      height: 100%;
      border: 1px solid @secondary-gray;
      padding: 10px;

      &--required {
        border: 2px solid @primary-red;
      }
    }
  }

  &__review-tutor {
    font-weight: normal;
    font-size: @font-size-small;
    
    &__answerStudent {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
    
    &__radio-group {
      margin: 1rem 0 1rem 0;

      &__radio {
        min-width: 114px;
        height: 48px;
        padding: 1rem;
        margin: 0 1rem 1rem 0;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    &__expected-answer {
      font-size: 16px;
      font-weight: bold;
      color: @answer-color-lms;
    }

    &__expected-answer-student {
      font-size: 16px;
      color: @answer-color-lms;
    }

    &__button {
      margin-top: 2rem;
      color: @gray600;
      text-decoration: underline;
    }
  }
}

.question-container .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.question-container .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.question-container .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.question-container .ant-checkbox-wrapper {
  margin: 5px 0;
  margin-left: 8px !important;
  display: flex;
  align-items: center;
}

.question-container .ant-checkbox + span {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 720px) {
  .question-container {
    &__review-tutor {
      margin-bottom: 5rem;
    }
  }
}

.spacing-options {
  margin-top: 5rem;
}