.option-navbar {
  font-weight: 600;
  font-size: 13px;
  color: white;
  cursor: pointer;
  gap: 0.5rem;
  font-family: Raleway, sans-serif;
  justify-content: center;
  align-items: center;

  &__label {
    color: white;
  }
}

@primary-grey: #AEAEAE;@primary-green: #74d98a;@secondary-red: #ee4b2b;@text-gray: #909090;@header-background: #FAFBFC;